import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Menu from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
// @material-ui/icons
import backtestimg from 'assets/img/backtest.png';
import introimg from "../../assets/img/gopipa_logo.png";
import Button from "../../components/CustomButtons/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import {getEtfIDA, getStockIDA} from "../../utils/strategy.utils";
import {getLoginUser} from "../../utils/user.utils";

const styles = {
    noWarp: {
        whiteSpace: 'nowrap',
    },
    chartBox: {
        fontFamily: 'Arial, Helvetica',
        height: 'auto',
        margin: '25px 10px',
        fontWeight: 600,
        border: '1px solid #000000DE',
        // maxWidth: '900px',
    },
    tabletitle: {
        fontSize: '18px',
        fontWeight: 550,
        padding: '10px 15px',
        color: '#000000DE',
        backgroundColor: '#e5e0e0',
    },
    error: {
        fontSize: '16px',
        fontWeight: 800,
        padding: '3px 0px',
        color: 'red',
    },
    imageBox: {
        display: 'block',
        position: 'relative',
        top: '5px',
        width: '1000px',
        margin: '10px',
        textAlign: 'center',
    },
    waitingMsg: {
        fontWeight: 400,
        fontSize: 20,
        textAlign: 'center',
        marginTop: 70,
        marginLeft: -80,
    },
    circularProgressTop: {
        marginTop: 20,
    },
    circularProgressDiv: {
        border: '1px solid 1px solid #d3d3d3',
        display: 'flex',
        justifyContent: 'center',
        height: 100,
    },
    idaBox: {
        position: 'relative',
        width: '100%',
        backgroundColor: 'white',
        padding: '0px',
        borderRadius: '5px',
        margin: '0px 0px 5px 10px',
        fontWeight: '400',
        fontSize: '16px',
        fontFamily: 'Arial, Helvetica',
        // border: '1px solid red',
    },
    title: {
        fontSize: '16px',
        fontWeight: 400,
        padding: '10px 0px 0px 10px',
        color: '#000000DE',
    },
    functionBox: {
        width: '275px',
        display: 'inline-block',
        height: 'auto',
        padding: '5px 0px 0px 0px',
    },
    menuList: {
        marginLeft: '-15px',
        width: '275px',
        border: '1px solid #000000DE',
        marginTop: '-8px',
        padding: '5px 15px',
        borderRadius: '5px',
    },
    menuItem: {
        color: 'black',
        fontSize: '16px',
        '& .Mui-selected': {
            display: 'block',
            backgroundColor: '#EBEBEB',
        },
        fontFamily: 'Arial, Helvetica',
        color: '#000000DE',
    },
};

const useStyles = makeStyles(styles);

export default function IDAStockCompareView() {
    const [idaColumns, setIdaColumns] = React.useState([]);
    const [idaTable, setIdaTable] = React.useState([]);
    const [tableWidth, setTableWidth] = React.useState('795px');
    const [tableTitle, setTableTitle] = React.useState('');
    const [updateTime, setUpdateTime] = React.useState('');
    const [progress, setProgress] = React.useState(false);
    const [menuIndex, setMenuIndex] = React.useState(1);
    const [groupIndex, setGroupIndex] = React.useState(1);
    const [stockColumn, setStockColumn] = React.useState([]);
    const [etfColumn, setEtfColumn] = React.useState([]);
    const [showGroupMenu, setShowGroupMenu] = React.useState(false);
    const [showETFCompare, setShowETFCompare] = React.useState(true);
    const [showStockCompare, setShowStockCompare] = React.useState(false);
    const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
    const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
    const open1 = Boolean(anchorEl1);
    const open2 = Boolean(anchorEl2);

    const menu_options = [
        'Select Comparison',
        'Top 10 Major ETF Momentum',
        'Bottom 10 Major ETF Momentum',
        'Top 10 Stock Momentum',
        'Bottom 10 Stock Momentum',
    ]

    const group_options = [
        'Select Group',
        'All Sectors',
        'All Sectors with High Quality Filter',
        'Value Sectors',
        'Value Sectors with High Quality Filter',
        'Growth Sectors',
        'Growth Sectors with High Quality Filter',
        'Technology Sector',
        'Technology Sector with High Quality Filter',
    ]

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setMenuIndex(index);
        setAnchorEl1(null);
        switch (index) {
            case 1:
                setTableWidth('795px');
                setShowStockCompare(false);
                getDataTable(13, 1);
                setTableTitle('Top 10 ETFs With The Highest Momentum Scores')
                setEtfColumn(etf1_momentum_columns);
                setShowETFCompare(true);
                setShowGroupMenu(false);
                break;
            case 2:
                setTableWidth('795px');
                setShowStockCompare(false);
                getDataTable(13, 2);
                setTableTitle('Bottom 10 ETFs With The Lowest Momentum Scores');
                setEtfColumn(etf2_momentum_columns);
                setShowETFCompare(true);
                setShowGroupMenu(false);
                break;
            case 3:
                setTableWidth('555px');
                setShowETFCompare(false);
                setGroupIndex(1);
                getDataTable(14, 1);
                setTableTitle('Top 10 Stocks With The Highest Momentum Scores');
                setStockColumn(stock1_momentum_columns);
                setShowStockCompare(true);
                setShowGroupMenu(true);
                break;
            case 4:
                setTableWidth('555px');
                setShowETFCompare(false);
                setGroupIndex(1);
                getDataTable(14, 2);
                setTableTitle('Bottom 10 Stocks With The Lowest Momentum Scores');
                setStockColumn(stock1_momentum_columns);
                setShowStockCompare(true);
                setShowGroupMenu(true);
                break;
            default:
                break;
        }
    };

    const handleGroupItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setGroupIndex(index);
        setAnchorEl2(null);
        getDataTable(14, menuIndex -2);
        switch (index) {
            case 1:
                setStockColumn(stock1_momentum_columns);
                break;
            case 2:
                setStockColumn(stock2_momentum_columns);
                break;
            case 3:
                setStockColumn(stock3_momentum_columns);
                break;
            case 4:
                setStockColumn(stock4_momentum_columns);
                break;
            case 5:
                setStockColumn(stock5_momentum_columns);
                break;
            case 6:
                setStockColumn(stock6_momentum_columns);
                break;
            case 7:
                setStockColumn(stock7_momentum_columns);
                break;
            case 8:
                setStockColumn(stock8_momentum_columns);
                break;
            default:
                break;
        }
    };

    const handleClickMenuItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const handleClickGroupItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const etf1_momentum_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}></div>
                ),
            selector: 'header',
            // sortable: true,
            width: '100px',
            cell: (row) => {
                const data = row.header? row.header:''
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Top 10 ETFs (Average)</div>
                ),
            selector: 'aveRank',
            // sortable: true,
            width: '130px',
            cell: (row) => {
                const data = row.aveRank? row.aveRank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Top 10 ETFs (M2 Model)</div>
                ),
            selector: 'm2Rank',
            // sortable: true,
            width: '130px',
            cell: (row) => {
                const data = row.m2Rank? row.m2Rank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Top 10 ETFs (M4 Model)</div>
                ),
            selector: 'm4Rank',
            // sortable: true,
            // right: true,
            width: '130px',
            cell: (row) => {
                const data = row.m4Rank? row.m4Rank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Top 10 ETFs (M3 Model)</div>
                ),
            selector: 'm3Rank',
            // sortable: true,
            width: '130px',
            cell: (row) => {
                const data = row.m3Rank? row.m3Rank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Top 10 ETFs (M6 Model)</div>
                ),
            selector: 'm6Rank',
            // sortable: true,
            // right: true,
            width: '130px',
            cell: (row) => {
                const data = row.m6Rank? row.m6Rank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
    ];

    const etf2_momentum_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}></div>
                ),
            selector: 'header',
            // sortable: true,
            width: '100px',
            cell: (row) => {
                const data = row.header? row.header:''
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Bottom 10 ETFs (Average)</div>
                ),
            selector: 'aveRank',
            // sortable: true,
            width: '130px',
            cell: (row) => {
                const data = row.aveRank? row.aveRank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Bottom 10 ETFs (M2 Model)</div>
                ),
            selector: 'm2Rank',
            // sortable: true,
            width: '130px',
            cell: (row) => {
                const data = row.m2Rank? row.m2Rank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Bottom 10 ETFs (M4 Model)</div>
                ),
            selector: 'm4Rank',
            // sortable: true,
            // right: true,
            width: '130px',
            cell: (row) => {
                const data = row.m4Rank? row.m4Rank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Bottom 10 ETFs (M3 Model)</div>
                ),
            selector: 'm3Rank',
            // sortable: true,
            width: '130px',
            cell: (row) => {
                const data = row.m3Rank? row.m3Rank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Bottom 10 ETFs (M6 Model)</div>
                ),
            selector: 'm6Rank',
            // sortable: true,
            // right: true,
            width: '130px',
            cell: (row) => {
                const data = row.m6Rank? row.m6Rank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
    ];

    const stock1_momentum_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}></div>
                ),
            selector: 'header',
            // sortable: true,
            width: '100px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>All Sectors Average</div>
                ),
            selector: 'aveAllRank',
            // sortable: true,
            // right: true,
            width: '130px',
            cell: (row) => {
                const data = row.aveAllRank? row.aveAllRank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>All Sectors With M2</div>
                ),
            selector: 'all_m2_rank',
            // sortable: true,
            // right: true,
            width: '130px',
            cell: (row) => {
                const data = row.all_m2_rank? row.all_m2_rank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>All Sectors with M4</div>
                ),
            selector: 'all_m4_rank',
            // sortable: true,
            width: '130px',
            cell: (row) => {
                const data = row.all_m4_rank? row.all_m4_rank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
    ]

    const stock2_momentum_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}></div>
                ),
            selector: 'header',
            // sortable: true,
            width: '100px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>All Sectors with HQ Average</div>
                ),
            selector: 'aveAllHqRank',
            // sortable: true,
            // right: true,
            width: '130px',
            cell: (row) => {
                const data = row.aveAllHqRank? row.aveAllHqRank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>All Sectors with HQ M3</div>
                ),
            selector: 'allhq_m3_rank',
            // sortable: true,
            // right: true,
            width: '130px',
            cell: (row) => {
                const data = row.allhq_m3_rank? row.allhq_m3_rank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>All Sectors with HQ M6</div>
                ),
            selector: 'allhq_m6_rank',
            // sortable: true,
            // right: true,
            width: '130px',
            cell: (row) => {
                const data = row.allhq_m6_rank? row.allhq_m6_rank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
    ]

    const stock3_momentum_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}></div>
                ),
            selector: 'header',
            // sortable: true,
            width: '100px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Value Sectors Average</div>
                ),
            selector: 'aveValueRank',
            // sortable: true,
            // right: true,
            width: '130px',
            cell: (row) => {
                const data = row.aveValueRank? row.aveValueRank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Value Sectors with M2</div>
                ),
            selector: 'value_m2_rank',
            // sortable: true,
            // right: true,
            width: '130px',
            cell: (row) => {
                const data = row.value_m2_rank? row.value_m2_rank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Value Sectors with M4</div>
                ),
            selector: 'value_m4_rank',
            // sortable: true,
            // right: true,
            width: '130px',
            cell: (row) => {
                const data = row.value_m4_rank? row.value_m4_rank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
    ]

    const stock4_momentum_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}></div>
                ),
            selector: 'header',
            // sortable: true,
            width: '100px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Value Sectors with HQ Average</div>
                ),
            selector: 'aveValueHqRank',
            sortable: true,
            width: '130px',
            cell: (row) => {
                const data = row.aveValueHqRank? row.aveValueHqRank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Value Sectors with HQ M3</div>
                ),
            selector: 'valuehq_m3_rank',
            sortable: true,
            width: '130px',
            cell: (row) => {
                const data = row.valuehq_m3_rank? row.valuehq_m3_rank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Value Sectors with HQ M6</div>
                ),
            selector: 'valuehq_m6_rank',
            sortable: true,
            width: '130px',
            cell: (row) => {
                const data = row.valuehq_m6_rank? row.valuehq_m6_rank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
    ]

    const stock5_momentum_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}></div>
                ),
            selector: 'header',
            // sortable: true,
            width: '100px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Growth Sectors Average</div>
                ),
            selector: 'aveGrowthRank',
            width: '130px',
            cell: (row) => {
                const data = row.aveGrowthRank? row.aveGrowthRank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Growth Sectors with M2</div>
                ),
            selector: 'growth_m2_rank',
            width: '130px',
            cell: (row) => {
                const data = row.growth_m2_rank? row.growth_m2_rank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Growth Sectors with M4</div>
                ),
            selector: 'growth_m4_rank',
            width: '130px',
            cell: (row) => {
                const data = row.growth_m4_rank? row.growth_m4_rank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
    ]

    const stock6_momentum_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}></div>
                ),
            selector: 'header',
            // sortable: true,
            width: '100px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Growth Sectors with HQ Average</div>
                ),
            selector: 'aveGrowthHqRank',
            width: '130px',
            cell: (row) => {
                const data = row.aveGrowthHqRank? row.aveGrowthHqRank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Growth Sectors with HQ M3</div>
                ),
            selector: 'growthhq_m3_rank',
            width: '130px',
            cell: (row) => {
                const data = row.growthhq_m3_rank? row.growthhq_m3_rank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Growth Sectors with HQ M6</div>
                ),
            selector: 'growthhq_m6_rank',
            width: '130px',
            cell: (row) => {
                const data = row.growthhq_m6_rank? row.growthhq_m6_rank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
    ]

    const stock7_momentum_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}></div>
                ),
            selector: 'header',
            // sortable: true,
            width: '100px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Tech Sectors Average</div>
                ),
            selector: 'aveTechRank',
            // sortable: true,
            // right: true,
            width: '130px',
            cell: (row) => {
                const data = row.aveTechRank? row.aveTechRank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Tech Sectors with M2</div>
                ),
            selector: 'tech_m2_rank',
            // sortable: true,
            // right: true,
            width: '130px',
            cell: (row) => {
                const data = row.tech_m2_rank? row.tech_m2_rank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Tech Sectors with M4</div>
                ),
            selector: 'tech_m4_rank',
            // sortable: true,
            // right: true,
            width: '130px',
            cell: (row) => {
                const data = row.tech_m4_rank? row.tech_m4_rank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
    ]

    const stock8_momentum_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}></div>
                ),
            selector: 'header',
            // sortable: true,
            width: '100px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Tech Sectors with HQ Average</div>
                ),
            selector: 'aveTechHqRank',
            sortable: true,
            // right: true,
            width: '130px',
            cell: (row) => {
                const data = row.aveTechHqRank? row.aveTechHqRank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Tech Sectors with HQ M3</div>
                ),
            selector: 'techhq_m3_rank',
            sortable: true,
            // right: true,
            width: '130px',
            cell: (row) => {
                const data = row.techhq_m3_rank? row.techhq_m3_rank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Tech Sectors with HQ M6</div>
                ),
            selector: 'techhq_m6_rank',
            // sortable: true,
            width: '130px',
            cell: (row) => {
                const data = row.techhq_m6_rank? row.techhq_m6_rank:'N/A'
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
    ];

    const getDataTable = (func, dim) => {
        getStockIDA("", func, 0, 0, dim)
            .then((response) => {
                if (response.data.code === 200) {
                    const data = response.data.data;
                    setProgress(false);
                    if (data.idaTable !== null) {
                        setIdaTable(data.idaTable);
                    } else {
                        setIdaTable([]);
                    }
                    if (data.updateTime !== null) {
                        setUpdateTime(data.updateTime)
                    }
                } else  {
                    const err = response?.data?.errMsg? response.data.errMsg : null;
                    setIdaColumns([]);
                    setIdaTable([]);
                    setProgress(false);
                }
            })
            .catch((error) => {
                setIdaColumns([]);
                setProgress(false);
                setIdaTable([]);
                console.debug(error);
            });
    }

    React.useEffect(() => {
        setProgress(true);
        setTableTitle('Top 10 ETFs With The Highest Momentum Scores');
        getDataTable(13, 1);
        setEtfColumn(etf1_momentum_columns);
    }, []);

    const classes = useStyles();

    return (
        <>
            <div>
                {/*<div className={classes.title} style={{marginTop: '25px'}}>Select one of the Comparisons:</div>*/}
                <div className={classes.title} style={{marginTop: '15px'}} />
                <div className={classes.idaBox}>
                    <span className={classes.functionBox}>
                        <Box
                            ml={2}
                            sx={{ width: '50%',
                                maxWidth: 200,
                            }}
                        >
                            <List
                                component="nav"
                                aria-label="Functions"
                            >
                                <ListItem
                                    className={classes.menuList}
                                    button
                                    id="lock-button"
                                    aria-haspopup="listbox"
                                    onClick={handleClickMenuItem}
                                >
                                    <ListItemText
                                        primary={menu_options[menuIndex]}
                                        secondary="comparison"
                                    />
                                </ListItem>
                            </List>
                            <Menu
                                className={classes.menuItem}
                                id="function-menu"
                                anchorEl={anchorEl1}
                                open={open1}
                                onClose={handleClose1}
                                MenuListProps={{
                                    'aria-labelledby': 'lock-button',
                                    role: 'listbox',
                                }}
                            >
                                {menu_options.map((option, index) => (
                                    <MenuItem
                                        className={classes.menuItem}
                                        key={option}
                                        disabled={index === 0}
                                        selected={index === menuIndex}
                                        onClick={(event) => handleMenuItemClick(event, index)}
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </span>
                { showGroupMenu && (
                        <span className={classes.functionBox}>
                            <Box
                                ml={5}
                                sx={{ width: '50%',
                                    maxWidth: 200,
                                }}
                            >
                                <List
                                    component="nav"
                                    aria-label="Functions"
                                >
                                    <ListItem
                                        className={classes.menuList}
                                        style={{width: '340px'}}
                                        button
                                        id="lock-button"
                                        aria-haspopup="listbox"
                                        onClick={handleClickGroupItem}
                                    >
                                        <ListItemText
                                            primary={group_options[groupIndex]}
                                            secondary="group"
                                        />
                                    </ListItem>
                                </List>
                                <Menu
                                    className={classes.menuItem}
                                    id="function-menu"
                                    anchorEl={anchorEl2}
                                    open={open2}
                                    onClose={handleClose1}
                                    MenuListProps={{
                                        'aria-labelledby': 'lock-button',
                                        role: 'listbox',
                                    }}
                                >
                                    {group_options.map((option, index) => (
                                        <MenuItem
                                            className={classes.menuItem}
                                            key={option}
                                            disabled={index === 0}
                                            selected={index === groupIndex}
                                            onClick={(event) => handleGroupItemClick(event, index)}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                    </span>
                )}
                </div>
                <hr style={{margin: '15px 0px 5px 0px'}}/>
                { progress && (
                    <div className={classes.circularProgressDiv}>
                        <CircularProgress className={classes.circularProgressTop} />
                        <div className={classes.waitingMsg}>Please wait...</div>
                    </div>
                )}
                { showETFCompare && (
                    <div className={classes.chartBox} style={{maxWidth : tableWidth}}>
                        <div className={classes.tabletitle}>{tableTitle} <br/><small>(Based on End Of Day Data of {updateTime})</small></div>
                        <div>
                            <DataTable
                                data={idaTable}
                                columns={etfColumn}
                                fixedHeaderScrollHeight="60vh"
                                fixedHeader
                                highlightOnHover
                                striped
                                defaultSortFieldId={1}
                                sortIcon={<SortIcon />}
                                noHeader={true}
                            />
                        </div>
                        <hr style={{margin: '5px'}}/>
                    </div>
                )}
                { showStockCompare && (
                    <div className={classes.chartBox} style={{maxWidth : tableWidth}}>
                        <div className={classes.tabletitle}>{tableTitle} <br/><small>(Based on End Of Day Data of {updateTime})</small></div>
                        <div>
                            <DataTable
                                data={idaTable}
                                columns={stockColumn}
                                fixedHeaderScrollHeight="60vh"
                                fixedHeader
                                highlightOnHover
                                striped
                                defaultSortFieldId={1}
                                sortIcon={<SortIcon />}
                                noHeader={true}
                            />
                        </div>
                        <hr style={{margin: '5px'}}/>
                    </div>
                )}
            </div>
        </>
    );
}
