import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "../../components/CustomButtons/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactHtmlParser from "html-react-parser";
import {getStockIDA, getWatchList, getSymbols, saveSymbols, saveWatchList, deleteWatchList} from "../../utils/strategy.utils";
import {setUserOption, getUserOption} from "../../utils/dashboard.utils";
import {getTrackAccountForIDA} from '../../utils/user.utils';
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import {explanationText} from "./explanation-text";

const styles = {
    noWarp: {
        whiteSpace: 'nowrap',
    },
    inputBox: {
        fontSize: '16px',
        fontWeight: 200,
        padding: '10px 10px',
        maringTop: '10px',
    },
    textBox: {
        fontSize: '16px',
        fontWeight: 200,
        padding: '5px 10px',
        maringTop: '5px',
        width: '300px',
    },
    newListBox: {
        fontSize: '16px',
        fontWeight: 200,
        padding: '5px 10px',
        margin:'5px',
        width: '500px',
    },
    // submitBox: {
    //     height: '35px',
    //     marginLeft: '20px',
    //     marginTop: '-200px',
    //     textTransform: 'capitalize',
    //     fontSize: '12px',
    // },
    submitBox: {
        width: '135px',
        height: '35px',
        marginLeft: '30px',
        textTransform: 'capitalize',
        fontSize: '12px',
    },
    addBox: {
        borderRadius: '5px',
        width: '135px',
        height: '35px',
        marginLeft: '40px',
        textTransform: 'capitalize',
        fontSize: '12px',
    },
    deleteBox: {
        width: '135px',
        height: '35px',
        marginLeft: '30px',
        textTransform: 'capitalize',
        fontSize: '12px',
    },
    addNameButton: {
        height: '35px',
        width: '142px',
        marginLeft: '100px',
        marginTop: '0px',
        textTransform: 'capitalize',
        fontSize: '12px',
    },
    warningButton: {
        height: '35px',
        width: '100px',
        marginLeft: '55px',
        marginTop: '5px',
        textTransform: 'capitalize',
        fontSize: '12px',
    },
    chartBox: {
        fontFamily: 'Arial, Helvetica',
        height: 'auto',
        margin: '25px 10px',
        fontWeight: 500,
        border: '1px solid #000000DE',
        // maxWidth: '900px',
    },
    tabletitle: {
        fontSize: '18px',
        fontWeight: 550,
        padding: '10px 8px',
        color: '#000000DE',
        backgroundColor: '#e5e0e0',
        height: '50px',
        align: 'center',
    },
    symbolslist: {
        fontFamily: 'Arial, Helvetica',
        height: '100px',
        fontSize: '15px',
        fontWeight: 200,
        padding: '25px 30px',
        margin: '25px 50px',
        marginTop: 70,
        color: '#000000DE',
    },
    error: {
        fontSize: '16px',
        fontWeight: 800,
        padding: '3px 0px',
        margin:'0px 15px 5px 15px',
        color: 'red',
    },
    waitingMsg: {
        fontWeight: 400,
        fontSize: 20,
        textAlign: 'center',
        marginTop: 70,
        marginLeft: -80,
    },
    circularProgressTop: {
        marginTop: 20,
    },
    circularProgressDiv: {
        border: '1px solid 1px solid #d3d3d3',
        display: 'flex',
        justifyContent: 'center',
        height: 100,
    },
    idaBox: {
        position: 'relative',
        width: 'auto',
        backgroundColor: 'white',
        padding: '0px',
        borderRadius: '5px',
        margin: '0px 0px 5px 10px',
        fontWeight: '400',
        fontSize: '16px',
        fontFamily: 'Arial, Helvetica',
    },
    title: {
        fontFamily: 'Roboto, Arial, Helvetica',
        fontSize: '16px',
        fontWeight: 200,
        margin: '0px 0px 5px 5px',
        padding: '10px 0px 0px 5px',
        color: '#000000DE',
    },
    functionBox: {
        width: '270px',
        display: 'inline-block',
        height: 'auto',
        padding: '0px 0px 0px 10px',
    },
    menuList: {
        marginLeft: '-15px',
        width: '270px',
        border: '1px solid #000000DE',
        marginTop: '-8px',
        padding: '5px 15px',
        borderRadius: '5px',
    },
    menuItem: {
        color: 'black',
        fontSize: '16px',
        width: '302px',
        '& .Mui-selected': {
            display: 'block',
            backgroundColor: '#EBEBEB',
        },
        fontFamily: 'Arial, Helvetica',
        color: '#000000DE',
    },
    position: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        width: '600px',
        backgroundColor: 'whitesmoke',
        padding: '10px',
        borderRadius: '5px',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
        overflow: 'auto',
    },
    warning: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        width: '400px',
        backgroundColor: 'whitesmoke',
        padding: '10px',
        borderRadius: '5px',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
        overflow: 'auto',
    },
    alert: {
        position: 'absolute',
        marginTop:'130px',
        left: '400px',
        width: '220px',
        backgroundColor: 'whitesmoke',
        align: 'center',
        padding: '30px',
        fontWeight: '600',
        borderRadius: '5px',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
        overflow: 'auto',
    },
    infoButton: {
        borderRadius: 5,
        marginLeft: '35px',
        backgroundColor: '#FFF',
        border:'1px soild #E5E0E0',
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'black',
        opacity: 0.4,
        zIndex: 8888,
    },
    subscribe: {
        borderRadius: '5px',
        width: '90px',
        height: '35px',
        textTransform: 'capitalize',
        fontSize: '12px',
        position: 'absolute',
        left: '1010px',
        marginTop: '-3px',
    },
    explanation: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        width: '900px',
        height: '700px',
        backgroundColor: 'whitesmoke',
        padding: '20px 30px',
        borderRadius: '5px',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
        overflow: 'auto',
        fontWeight: '400',
        fontSize: '14px',
    },
}

const useStyles = makeStyles(styles);

let initSubscription = [
    {id: 1, subscribe: false},
    {id: 2, subscribe: false},
    {id: 3, subscribe: false},
    {id: 4, subscribe: false},
    {id: 5, subscribe: false},
    {id: 6, subscribe: false},
    {id: 7, subscribe: false},
    {id: 8, subscribe: false},
]

export default function MyWatchListView() {
    const [updateMenu, setUpdateMenu] = React.useState(true);
    const [symbolsInput, setSymbolsInput] = React.useState("");
    const [newSymbolsInput, setNewSymbolsInput] = React.useState("");
    const [nameInput, setNameInput] = React.useState("");
    const [watchList, setWatchList] = React.useState("");
    const [portfolioList, setPortfolioList] = React.useState("");
    const [watchListMenu, setWatchListMenu] = React.useState([]);
    const [portfolioListMenu, setPortfolioListMenu] = React.useState([]);
    const [updateTime, setUpdateTime] = React.useState('');
    const [idaColumns, setIdaColumns] = React.useState([]);
    const [idaTable, setIdaTable] = React.useState([]);
    const [tableTitle, setTableTitle] = React.useState('');
    const [progress, setProgress] = React.useState(false);
    const [portfolioIndex, setPortfolioIndex] = React.useState(0);
    const [menuIndex, setMenuIndex] = React.useState(1);
    const [portfolioListIndex, setPortfolioListIndex] = React.useState(0);
    const [watchlistIndex, setWatchlistIndex] = React.useState(0);
    const [showDetection, setShowDetection] = React.useState(false);
    const [showTAMetric, setShowTAMetric] = React.useState(false);
    const [showUserList, setShowUserList] = React.useState(true);
    const [showAddListPopup, setShowAddListPopup] = React.useState(false);
    const [showWarningMsg, setShowWarningMsg] = React.useState(false);
    const [createErrorMsg, setCreateErrorMsg] = React.useState("");
    const [showAlertMsg, setShowAlertMsg] = React.useState(false);
    const [showExplanation, setShowExplanation] = React.useState(false);
    const [explanationMsg, setExplanationMsg] = React.useState('');
    const [alertMsg, setAlertMsg] = React.useState('');
    const [accountMenuProp, setAccountMenuProp] = React.useState('1px solid #000000');
    const [watchlistMenuProp, setWatchlistMenuProp] = React.useState('1px solid #000000');
    const [disableMenu, setDisableMenu] = React.useState(true);
    const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
    const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
    const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null);
    const open1 = Boolean(anchorEl1);
    const open2 = Boolean(anchorEl2);
    const open3 = Boolean(anchorEl3);
    const classes = useStyles();

    //initialize subscription
    const [subscription, setSubscription] = React.useState(
        initSubscription
    );

    const menu_options = [
        'Select function',
        'Pattern & Abnormality Detection',
        'TA Metrics',
    ]

    const getWatchListId = () => {
        if (watchList) {
            if (watchList.length > 0) {
                for (let i = 0; i < watchList.length; i++) {
                    if (i === watchlistIndex) {
                        setSymbolsInput(watchList[i]['value']);
                        let id = watchList[i]['id'];
                        return id;
                    }
                }
            }
        } else {
            return null;
        }
    }

    const handlePortfolioItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setPortfolioIndex(index);
        setAnchorEl3(null);
        var account = portfolioList[index-1];
        switch (menuIndex) {
            case 1:
                setLoading(true);
                setShowTAMetric(false);
                // var id = getWatchListId();
                getDataTable(27, "", 3, "", account.id);
                setAccountMenuProp('2px solid #0000FF');
                setWatchlistMenuProp('1px solid #000000');
                break;
            case 2:
                setLoading(true);
                setShowDetection(false);
                // var id = getWatchListId();
                getDataTable(28, "", 1, "", account.id);
                setAccountMenuProp('2px solid #0000FF');
                setWatchlistMenuProp('1px solid #000000');
                break;
            default:
                break;
        }
    };


    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setMenuIndex(index);
        setAnchorEl1(null);
        switch (index) {
            case 1:
                setLoading(true);
                setShowTAMetric(false);
                if (watchlistMenuProp.indexOf('2px') === 0) {
                    //wath list
                    var id = watchList[watchlistIndex]['id'];
                    getDataTable(16, "", 3, id, "");
                } else {
                    //get account
                    var account = portfolioList[index-1];
                    getDataTable(27, "", "", "", account.id);
                }
                break;
            case 2:
                setLoading(true);
                setShowDetection(false);
                if (watchlistMenuProp.indexOf('2px') === 0) {
                    //watch list
                    var id = watchList[watchlistIndex]['id'];
                    getDataTable(17, "", 1, id, "");
                } else {
                    //get account
                    var account = portfolioList[index-1];
                    getDataTable(28, "", "", "", account.id);
                }
                break;
            default:
                break;
        }
    };

    const handleWatchListClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setWatchlistIndex(index);
        setAnchorEl2(null);
        if (watchList) {
            if (watchList.length > 0) {
                for (let i = 0; i < watchList.length; i++) {
                    if (i === index) {
                        setSymbolsInput(watchList[i]['value']);
                        let id = watchList[i]['id'];
                        setLoading(true);
                        if (menuIndex == 1 ) {
                            getDataTable(16, "", 3, id, "");
                            setWatchlistMenuProp('2px solid #0000FF');
                            setAccountMenuProp('1px solid #000000');
                        } else {
                            getDataTable(17, "", 1, id, "");
                            setWatchlistMenuProp('2px solid #0000FF');
                            setAccountMenuProp('1px solid #000000');
                        }
                    }
                }
            }
        }
    };

    const handleClickMenuItem3 = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl3(event.currentTarget);
    };

    const handleClickMenuItem1 = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const handleClickMenuItem2 = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const handleClose3 = () => {
        setAnchorEl3(null);
    };

    const handleChange = (e) => {
        if (showAlertMsg === false) {
            e.preventDefault();
            let input = e.target.value;
            let c = input.charAt(input.length - 1);
            if (c === '.') {
                let res = input.substring(0, input.length - 1);
                input = res + ',';
            } else if (c === '\n') {
                return;
            }
            setSymbolsInput(input.toUpperCase());
        }
    };

    const handleKeyPress = (e: any) => {
        setShowAlertMsg(false);
        if (!e.key.match(/^[0-9a-zsA-Z,.]/)) {
            setAlertMsg('Letter ' + e.key + ' is not allowed');
            setShowAlertMsg(true);
        }
    };

    const handleNewChange = (e) => {
        e.preventDefault();
        setCreateErrorMsg("");
        let input = e.target.value;
        let c = input.charAt(input.length - 1);
        if (c === '.') {
            let res = input.substring(0, input.length - 1);
            input = res + ',';
        } else if (c === '\n') {
            return;
        }
        setNewSymbolsInput(input.toUpperCase());
    };

    const handleNameInput = (e) => {
        e.preventDefault();
        setCreateErrorMsg("");
        setNameInput(e.target.value);
    };

    const handleSaveSubmit = () => {
        setLoading(true);
        let input = symbolsInput.replace(/ /gm, '');
        setSymbolsInput(input);
        let id = null;
        if (watchList.length > 0) {
            id = watchList[watchlistIndex]['id'];
            let name = watchList[watchlistIndex]['name'];
            const params = {
                "id": id,
                "name": name,
                "value": input,
            };
            saveWatchList(params);
        } else {
            saveSymbols(input);
        }
        if (menuIndex === 2) {
            setTimeout(function () {
                getWatchListSymbols(false);
                getDataTable(17, "", 1, id, "");
                setWatchlistMenuProp('2px solid #0000FF');
                setAccountMenuProp('1px solid #000000');
            }, 2000);
        } else {
            setTimeout(function () {
                getWatchListSymbols(false);
                getDataTable(16, "", 3, id, "");
                setWatchlistMenuProp('2px solid #0000FF');
                setAccountMenuProp('1px solid #000000');
            }, 2000);
        }
    }

    const handleCreateButton = () => {
        setNameInput("");
        setNewSymbolsInput("");
        setShowAddListPopup(true);
    }

    const handleSubscribe = (id) => {
        const updated = subscription;
        let setting = updated[id];
        updated[id].subscribe = !setting.subscribe;
        setSubscription(updated);
        setTimeout(function () {
            let option = JSON.stringify(updated)
            setUserOption('w', encodeURIComponent(option));
            var id = getWatchListId();
            getDataTable(16, "", 3, id, "");
            setWatchlistMenuProp('2px solid #0000FF');
            setAccountMenuProp('1px solid #000000');
        }, 1000);
    }

    const handleNewListSubmit = () => {
        if (nameInput.length === 0) {
            setCreateErrorMsg("Error: Name field can't be empty");
            return;
        }
        setShowAddListPopup(false);
        let index = 0;
        if (watchList.length > 0) {
            index = watchListMenu.length;
        }
        const params = {
            "name": nameInput,
            "value": newSymbolsInput,
        };
        setWatchlistIndex(index);
        saveWatchList(params);
        setTimeout(() => {
            getWatchListSymbols(false);
        }, 1000);
        setTimeout(() => {
            setSymbolsInput(newSymbolsInput);
            if (menuIndex === 1) {
                getDataTable(16, "", 3, "");
                setWatchlistMenuProp('2px solid #0000FF');
                setAccountMenuProp('1px solid #000000');
            } else {
                getDataTable(17, "", 1, "");
                setWatchlistMenuProp('2px solid #0000FF');
                setAccountMenuProp('1px solid #000000');
            }
        }, 3000);
    }

    const confirmDelete = () => {
        if (watchList.length > 0)
            setShowWarningMsg(true);
    }

    const handleDeleteListSubmit = () => {
        setShowWarningMsg(false);
        setShowAlertMsg(false);
        deletetWatchListSymbols();
    }

    const onCancel = () => {
        setShowAddListPopup(false);
        setShowWarningMsg(false);
        setShowAlertMsg(false);
        setAlertMsg('');
    }

    const handleOpenInfo = (detection) => {
        console.info(" (ID: %o", detection);
        setExplanationMsg(detection);
        setShowExplanation(true);
    };

    const handleOpenHelp = () => {
        setExplanationMsg(ReactHtmlParser(explanationText.taMetric));
        setShowExplanation(true);
    };

    const onClose = () => {
        setShowExplanation(false);
    }

    function updateHeader(header) : string {
        let newHeader = JSON.stringify(header).replace(/[{\"}]/g,' ').replace(':',' ');
        if (newHeader.indexOf('TA Metrics Buy/Sell Signals') > 0) {
            if (newHeader.indexOf(' 3.0') > 0)
                return newHeader.replace('TA Metrics Buy/Sell Signals 3.0', '<u>Confirmed Buy Signal (Both Trend & Momentum turn positive, v=3)</u>');
            else if (newHeader.indexOf(' 2.0') > 0)
                return newHeader.replace('TA Metrics Buy/Sell Signals 2.0', 'Early Buy Signal (Trend turns positive, v=2)');
            else if (newHeader.indexOf(' 1.0') > 0)
                return newHeader.replace('TA Metrics Buy/Sell Signals 1.0', 'Early Buy Signal (Momentum turns positive, v=1)');
            else if (newHeader.indexOf(' -3.0') > 0)
                return newHeader.replace('TA Metrics Buy/Sell Signals -3.0 ', '<u>Confirmed Sell Signal (Both Trend & Momentum turn negative, v=-3)</u>');
            else if (newHeader.indexOf(' -2.0') > 0)
                return newHeader.replace('TA Metrics Buy/Sell Signals -2.0', 'Early Sell Signal (Trend turns negative, v=-2)');
            else if (newHeader.indexOf(' -1.0') > 0)
                return newHeader.replace('TA Metrics Buy/Sell Signals -1.0', 'Early Sell Signal (Momentum turns negative, v=-1)');
        } else if (newHeader.indexOf('EMA Trend Signals') > 0) {
            if (newHeader.indexOf(' 2.0') > 0)
                return newHeader.replace('EMA Trend Signals 2.0', '<u>Confirmed Uptrend Signal (Price > EMA20 > EMA50, v=2)</u>');
            else if (newHeader.indexOf(' 1.0') > 0)
                return newHeader.replace('EMA Trend Signals 1.0', 'Early Uptrend Signal (Price > EMA20, v=1)');
            else if (newHeader.indexOf(' -2.0') > 0)
                return newHeader.replace('EMA Trend Signals -2.0', '<u>Confirmed Downtrend Signal (Price < EMA20 < EMA50, v=-2)</u>');
            else if (newHeader.indexOf(' -1.0') > 0)
                return newHeader.replace('EMA Trend Signals -1.0', 'Early Downtrend Signal (Price < EMA20, v=-1)');
        } else if (newHeader.indexOf('PSAR Trend Signals') > 0) {
            if (newHeader.indexOf(' 1.0') > 0)
                return newHeader.replace('PSAR Trend Signals 1.0', 'Uptrend Signal (Price > PSAR, v=1)');
            else if (newHeader.indexOf(' -1.0') > 0)
                return newHeader.replace('PSAR Trend Signals -1.0', 'Downtrend Signal (Price < PSAR, v=-1)');
        } else if (newHeader.indexOf('MACD Momentum Signals') > 0) {
            if (newHeader.indexOf(' 2.0') > 0)
                return newHeader.replace('MACD Momentum Signals 2.0', '<u>Confirmed Increasing Momentum Signal (MACD > Signal & MACD > 0, v=2)</u>');
            else if (newHeader.indexOf(' 1.0') > 0)
                return newHeader.replace('MACD Momentum Signals 1.0', 'Early Increasing Momentum Signal (MACD > Signal & MACD < 0, v=1)');
            else if (newHeader.indexOf(' -2.0') > 0)
                return newHeader.replace('MACD Momentum Signals -2.0', '<u>Confirmed Decreasing Momentum (MACD < Signal & MACD < 0, v=-2)</u>');
            else if (newHeader.indexOf(' -1.0') > 0)
                return newHeader.replace('MACD Momentum Signals -1.0', 'Early Decreasing Momentum (MACD < Signal & MACD > 0, v=-1)');
        } else if (newHeader.indexOf('Bolling Band Overbought/Oversold Signals') > 0) {
            if (newHeader.indexOf(' 1.0') > 0)
                return newHeader.replace('Bolling Band Overbought/Oversold Signals 1.0', 'Oversold Signal (Price < Lower BB, v=1)');
            else if (newHeader.indexOf(' -1.0') > 0)
                return newHeader.replace('Bolling Band Overbought/Oversold Signals -1.0', 'Overbought Signal (Price > Upper BB, v=-1)');
        } else if (newHeader.indexOf('RSI Overbought/Oversold Signals') > 0) {
            if (newHeader.indexOf(' 2.0') > 0)
                return newHeader.replace('RSI Overbought/Oversold Signals 2.0', 'Very Oversold Signal (RSI < 20, v=2)');
            else if (newHeader.indexOf(' 1.0') > 0)
                return newHeader.replace('RSI Overbought/Oversold Signals 1.0', 'Oversold Signal (RSI < 30, v=1)');
            else if (newHeader.indexOf(' -2.0') > 0)
                return newHeader.replace('RSI Overbought/Oversold Signals -2.0', 'Very Overbought Signal (RSI > 80, v=-2)');
            else if (newHeader.indexOf(' -1.0') > 0)
                return newHeader.replace('RSI Overbought/Oversold Signals -1.0', 'Overbought Signal (RSI > 70, v=-1)');
        } else if (newHeader.indexOf('Stock Breakout Signals') > 0) {
            if (newHeader.indexOf(' 1.0 ') > 0)
                return newHeader.replace('Stock Breakout Signals 1.0 ', 'Stocks Break-up - Buy Signal (v=1)');
            else if (newHeader.indexOf(' -1.0 ') > 0)
                return newHeader.replace('Stock Breakout Signals -1.0 ', 'Stocks Break-down - Sell Signal (v=-1)');
        } else if (newHeader.indexOf('Current State (R1) Change Event') > 0) {
            return newHeader;
        } else if (newHeader.indexOf('Current State (H1) Change Event') > 0) {
            return newHeader;
        } else if (newHeader.indexOf('Current 5 Day Prediction Change Event') > 0) {
            return newHeader;
        } else if (newHeader.indexOf('Current 5 Days Prediction (S1) Change Event') > 0) {
            return newHeader;
        } else if (newHeader.indexOf('Current 20 Days Prediction (M1) Change Event') > 0) {
            return newHeader;
        }
        return "";
    }

    const getDataTable = (func, symbols, dim, watchlist_id, accountid) => {
        getStockIDA(symbols, func, 1, 1, dim, watchlist_id, accountid)
            .then((response) => {
                if (response.data.code === 200) {
                    const data = response.data.data;
                    setLoading(false);
                    if (data.idaTable !== null) {
                        setIdaTable(data.idaTable);
                        if (func === 16) {
                            setShowDetection(true);
                        } else if (func === 17) {
                            setShowTAMetric(true);
                        } else if (func === 27) {
                            setShowDetection(true);
                        } else if (func === 28) {
                            setShowTAMetric(true);
                        }
                    }
                    if (data.updateTime !== null) {
                        setUpdateTime(data.updateTime)
                    }
                } else {
                    const err = response?.data?.errMsg? response.data.errMsg : null;
                    setIdaColumns([]);
                    setIdaTable([]);
                    setLoading(false);
                }
            })
            .catch((error) => {
                setIdaColumns([]);
                setLoading(false);
                setIdaTable([]);
                console.debug(error);
            });
    }

    const getUserSymbols = () => {
        getSymbols()
            .then((response) => {
                if (response.data.code === 200) {
                    const data = response.data.data;
                    if (data)
                        setSymbolsInput(data);
                    else
                        setSymbolsInput('');
                }
            })
            .catch((error) => {
                console.debug(error);
            });
    }

    const getWatchListSymbols = (useOption = true) => {
        getWatchList()
            .then((response) => {
                if (response.data.code === 200) {
                    const data = response.data.data;
                    if (data) {
                        setWatchList(data);
                        if (data.length > 0) {
                            let watchlist_menu = [];
                            for (let i = 0; i < data.length; i++) {
                                watchlist_menu.push(data[i]['name']);
                                if (i === watchlistIndex)
                                    setSymbolsInput(data[i]['value']);
                            }
                            setWatchListMenu(watchlist_menu);
                        } else {
                            let watchlist_menu = ['N/A (Please add new list)'];
                            setWatchListMenu(watchlist_menu);
                            if (useOption)
                                getUserSymbols();
                            else
                                setSymbolsInput("");
                        }
                    } else {
                        let watchlist_menu = ['N/A (Please add new list)'];
                        setWatchListMenu(watchlist_menu);
                        getUserSymbols();
                    }
                }
            })
            .catch((error) => {
                let watchlist_menu = ['N/A (Please add new list)'];
                setWatchListMenu(watchlist_menu);
                console.debug(error);
            });
    }

    const deletetWatchListSymbols = () => {
        var id = getWatchListId();
        setLoading(true);
        deleteWatchList(id);
        setTimeout(function () {
            //reload symbols
            getWatchListSymbols(false);
            if(watchlistIndex > 0) {
                const value = watchList[watchlistIndex - 1]['value'];
                const id = watchList[watchlistIndex - 1]['id'];
                setWatchlistIndex(watchlistIndex - 1);
                setSymbolsInput(value);
                if (menuIndex === 1) {
                    getDataTable(16, "", 3, id);
                } else {
                    getDataTable(17, "", 1, id);
                }
            } else {
                setWatchlistIndex(0);
                var watchlist_menu = ['N/A (Please add new list)'];
                setWatchListMenu(watchlist_menu);
                getDataTable(16, "", 3);
            }
        }, 2000);
    }

    const setLoading = (on) => {
        setProgress(on);
        setDisableMenu(on);
    }

    const tad_columns = [
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Symbol</div>
                ),
            selector: 'symbol',
            sortable: true,
            width: '90px',
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>TA State</div>
                ),
            selector: 'taRating',
            sortable: true,
            width: '135px',
            cell: (row) => {
                return (
                    <div style={{fontSize: '14px', textAlign: 'center'}}>
                        {row.taRating}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>TA String</div>
                ),
            selector: 'taString',
            sortable: true,
            // right: true,
            width: '190px',
            cell: (row) => {
                return (
                    <div style={{fontWeight: 550, fontSize: '14px', fontFamily: 'monospace', textAlign: 'center'}}>
                        {row.taString}
                    </div>
                )
            },
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize: '13px'}}>Trend</div>
                ),
            selector: 'trend',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.trend || row.trend === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.trend):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Trend 5 Day Slope</div>
                ),
            selector: 'trend5',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.trend5 || row.trend5 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.trend5):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Trend 20 Day Slope</div>
                ),
            selector: 'trend20',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.trend20 || row.trend20=== 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.trend20):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Momentum</div>
                ),
            selector: 'momentum',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.momentum || row.momentum === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.momentum):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Momentum 5 Day Slope</div>
                ),
            selector: 'momentum5',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.momentum5 || row.momentum5 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.momentum5):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Momentum 20 Day Slope</div>
                ),
            selector: 'momentum20',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.momentum20 || row.momentum20 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.momentum20):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Breadth</div>
                ),
            selector: 'breadth',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.breadth || row.breadth === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.breadth):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Breadth 5 Day Slope</div>
                ),
            selector: 'breadth5',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.breadth5 || row.breadth5 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.breadth5):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Breadth 20 Day Slope</div>
                ),
            selector: 'breadth20',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.breadth20 || row.breadth20 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.breadth20):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Oscillation</div>
                ),
            selector: 'oscillation',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.oscillation || row.oscillation === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.oscillation):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Oscillation 5 Day Slope</div>
                ),
            selector: 'oscillation5',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.oscillation5 || row.oscillation5 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.oscillation5):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>Oscillation 20 Day Slope</div>
                ),
            selector: 'oscillation20',
            sortable: true,
            right: true,
            width: '105px',
            cell: (row) => {
                const data = row.oscillation20 || row.oscillation20 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.oscillation20):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        /*
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>change_status1</div>
                ),
            selector: 'changeStatus1',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.changeStatus1 || row.changeStatus1 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.changeStatus1):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>ema_co1</div>
                ),
            selector: 'emaCo1',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.emaCo1 || row.emaCo1 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.emaCo1):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>psar_co1</div>
                ),
            selector: 'psarCo1',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.psarCo1 || row.psarCo1 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.psarCo1):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>macd_co1</div>
                ),
            selector: 'macdCo1',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.macdCo1 || row.macdCo1 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.macdCo1):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>bb_co1</div>
                ),
            selector: 'bbCo1',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.bbCo1 || row.bbCo1 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.bbCo1):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>rsi_co1</div>
                ),
            selector: 'rsiCo1',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.rsiCo1 || row.rsiCo1 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.rsiCo1):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        {
            name:
                (
                    <div style={{fontWeight: 700, fontSize:'13px'}}>breakout1</div>
                ),
            selector: 'breakout1',
            sortable: true,
            right: true,
            width: '110px',
            cell: (row) => {
                const data = row.breakout1 || row.breakout1 === 0? Intl.NumberFormat("en-US",{maximumFractionDigits: 5}).format(row.breakout1):"N/A";
                return (
                    <div>
                        {data}
                    </div>
                )
            }
        },
        */
    ];

    React.useEffect(() => {
        setLoading(true);
        getWatchListSymbols();
        //get track accounts
        getTrackAccountForIDA()
            .then((response) => {
                const data = response && response?.data ? response.data.data : null;
                if (data) {
                    setPortfolioList(data);
                    if (data.length > 0) {
                        let list_menu = [];
                        list_menu.push('Select Account');
                        for (let i = 0; i < data.length; i++) {
                            list_menu.push(data[i]['trackAccountName']);
                        }
                        setPortfolioListMenu(list_menu);
                        //fetch signal
                        // var account = data[menuIndex];
                        // console.log('portfolio: %o', account.id);
                        // getDataTable(27, "", account.id);
                    } else {
                        let list_menu = ['N/A'];
                        setPortfolioListMenu(list_menu);
                    }
                } else {
                    let watchlist_menu = ['N/A'];
                    setPortfolioListMenu(watchlist_menu);
                }
            })
        //get subscription setting
        getUserOption("w")
            .then((response) => {
                if (response?.data.code === 200) {
                    let option = response?.data?.data;
                    if (option != null) {
                        var dataobj = JSON.parse(option);
                        setSubscription(dataobj);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
        setTimeout(function () {
            getDataTable(16, "", 3);
            setWatchlistMenuProp('2px solid #0000FF');
        }, 2500);
    }, []);

    return (
        <>
            <div className={classes.title} style={{marginTop:'15px'}}>Select one of the functions:</div>
            <div className={classes.functionBox}>
                <Box
                    ml={2}
                    sx={{ width: '50%',
                        maxWidth: 200,
                    }}
                >
                    <List
                        component="nav"
                        aria-label="Functions"
                    >
                        <ListItem
                            className={classes.menuList}
                            button
                            id="lock-button"
                            aria-haspopup="listbox"
                            onClick={handleClickMenuItem1}
                        >
                            <ListItemText
                                primary={menu_options[menuIndex]}
                                // secondary="function"
                            />
                        </ListItem>
                    </List>
                    <Menu
                        className={classes.menuItem}
                        id="function-menu"
                        anchorEl={anchorEl1}
                        open={open1}
                        onClose={handleClose1}
                        MenuListProps={{
                            'aria-labelledby': 'lock-button',
                            role: 'listbox',
                        }}
                    >
                        {menu_options.map((option, index) => (
                            <MenuItem
                                className={classes.menuItem}
                                key={option}
                                disabled={disableMenu || index === 0}
                                selected={index === menuIndex}
                                onClick={(event) => handleMenuItemClick(event, index)}
                            >
                                {option}
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            </div>
            <hr style={{margin: '15px 0px 0px 0px'}}/>
            <div className={classes.title} style={{marginTop:'15px'}}>Select one of broker accounts (money accounts only):</div>
            <div className={classes.functionBox}>
                <Box
                    ml={2}
                    sx={{ width: '50%',
                        maxWidth: 200,
                    }}
                >
                    <List
                        component="nav"
                        aria-label="Functions"
                    >
                        <ListItem
                            className={classes.menuList}
                            style={{border: accountMenuProp}}
                            button
                            id="lock-button"
                            aria-haspopup="listbox"
                            onClick={handleClickMenuItem3}
                        >
                            <ListItemText
                                primary={portfolioListMenu[portfolioIndex]}
                            />
                        </ListItem>
                    </List>
                    <Menu
                        className={classes.menuItem}
                        id="function-menu"
                        anchorEl={anchorEl3}
                        open={open3}
                        onClose={handleClose3}
                        MenuListProps={{
                            'aria-labelledby': 'lock-button',
                            role: 'listbox',
                        }}
                    >
                        {portfolioListMenu.map((option, index) => (
                            <MenuItem
                                className={classes.menuItem}
                                key={option}
                                disabled={index === 0 || disableMenu}
                                selected={index === portfolioIndex}
                                onClick={(event) => handlePortfolioItemClick(event, index)}
                            >
                                {option}
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            </div>
            <div className={classes.title}>Select one of my watch lists:</div>
            <div>
                <span className={classes.functionBox}>
                    <Box
                        ml={2}
                        sx={{ width: '50%',
                            maxWidth: 200,
                        }}
                    >
                        <List
                            component="nav"
                            aria-label="Functions"
                        >
                            <ListItem
                                className={classes.menuList}
                                style={{border: watchlistMenuProp}}
                                button
                                id="lock-button"
                                aria-haspopup="listbox"
                                onClick={handleClickMenuItem2}
                            >
                                <ListItemText
                                    primary={watchListMenu[watchlistIndex]}
                                    // secondary="function"
                                />
                            </ListItem>
                        </List>
                        <Menu
                            className={classes.menuItem}
                            id="function-menu"
                            anchorEl={anchorEl2}
                            open={open2}
                            onClose={handleClose2}
                            MenuListProps={{
                                'aria-labelledby': 'lock-button',
                                role: 'listbox',
                            }}
                        >
                            { watchListMenu?.map((option, index) => (
                                <MenuItem
                                    className={classes.menuItem}
                                    key={option}
                                    disabled={disableMenu}
                                    selected={index === watchlistIndex}
                                    onClick={(event) => handleWatchListClick(event, index)}
                                >
                                    {option}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </span>
                <span>
                   <Button
                       onClick={handleCreateButton}
                       color="info"
                       className={classes.addBox}
                   >
                        Create Watch List
                    </Button>
                </span>
                <span>
                    <Button
                        onClick={handleSaveSubmit}
                        color="info"
                        className={classes.submitBox}
                    >
                        Save Watch List
                    </Button>
                </span>
                <span>
                    <Button
                        onClick={confirmDelete}
                        color="info"
                        className={classes.deleteBox}
                    >
                        Delete Watch List
                    </Button>
                </span>
            </div>
            { showAlertMsg && (
                <>
                    <div className={classes.overlay} onClick={onCancel}/>
                    <div className={classes.alert} onClick={onCancel}>
                        {alertMsg}
                    </div>
                </>
            )}
            <div className={classes.title}>Enter stock symbols separated by comma:</div>
            <div>
                <span className={classes.inputBox}>
                    <textarea style={{height: '200px', width: '770px', textAligh:'top'}}
                          type="text"
                          placeholder="List of stock symbols"
                          onChange={handleChange}
                          onKeyPress={handleKeyPress}
                          value={symbolsInput.toUpperCase()}
                    />
                </span>
            </div>
            <hr style={{margin: '15px 0px 5px 0px'}}/>
            { progress && (
                <div className={classes.circularProgressDiv}>
                    <CircularProgress className={classes.circularProgressTop} />
                    <div className={classes.waitingMsg}>Please wait...</div>
                </div>
            )}
            { showTAMetric && (
                <>
                    <div className={classes.chartBox} style={{maxWidth : 'auto'}}>
                        <div className={classes.tabletitle}>Technical Metrics <small>(Based on End Of Day Data of {updateTime})</small>
                            <Tooltip title="Click to show explanation">
                                <IconButton className={classes.infoButton} aria-label="strategyDetail" size="small" onClick={() => handleOpenHelp()}>
                                    <InfoIcon fontSize="default" /><small style={{marginLeft:'-4px'}}>Help</small>
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div style={{maxWidth : 'auto', overflowY: 'scroll'}}>
                            <DataTable
                                data={idaTable}
                                columns={tad_columns}
                                fixedHeaderScrollHeight="60vh"
                                fixedHeader
                                highlightOnHover
                                striped
                                defaultSortField={1}
                                sortIcon={<SortIcon />}
                                noHeader={true}
                                overflowY={true}
                                overflowYOffset="0px"
                            />
                        </div>
                    </div>
                </>
            )}
            { showDetection && (
                <>
                    <div className={classes.chartBox} style={{maxWidth : '1080px'}}>
                        {idaTable.map((detection, subid) => {
                            var setting = subscription[subid];
                            var buttonName, buttonColor;
                            if (setting.subscribe) {
                                buttonName = 'Unsubscribe';
                                buttonColor = '#0f727f'
                            } else {
                                buttonName = 'Subscribe';
                                buttonColor = '#17acc0';
                            }
                            return (<>
                                <div className={classes.tabletitle} style={{maxWidth: '1080px'}}>
                                    {detection.header} <small>(Based on End Of Day Data of {updateTime})</small>
                                    <span className={classes.tabletitle}>
                                        <Button
                                            onClick={() => handleSubscribe(subid)}
                                            className={classes.subscribe}
                                            style={{backgroundColor:buttonColor}}
                                        >
                                            {buttonName}
                                        </Button>
                                    </span>
                                </div>
                                {detection.detectionList.map((d, index) => {
                                    const content = updateHeader(d);
                                    if (content != '') {
                                        return (
                                            <>
                                                <div style={{
                                                    maxWidth: '1080px',
                                                    minHeight: '30px',
                                                    margin: '10px, 25px',
                                                    padding: '15px'
                                                }}>{ReactHtmlParser(content)}</div>
                                                <hr style={{margin: '0px'}}/>
                                            </>
                                        )
                                    }
                                })}
                            </>
                            )})}
                    </div>
                    {/*<hr style={{margin: '5px'}}/>*/}
                </>
            )}
            { showWarningMsg && (
                <>
                    <div className={classes.overlay} onClick={onCancel}/>
                    <div className={classes.warning}>
                        <div className={classes.title} style={{margin: '0px 10px 0 10px'}}>Do you really want to delete <b>{watchList? watchList[watchlistIndex]['name'] : ''}</b>?</div>
                        <div style={{margin: '5px 10px'}}>
                            <span>
                                <Button
                                    onClick={handleDeleteListSubmit}
                                    color="info"
                                    className={classes.warningButton}
                                >
                                    OK
                                </Button>
                            </span>
                            <span>
                                <Button
                                    onClick={onCancel}
                                    color="info"
                                    className={classes.warningButton}
                                >
                                    Cancel
                                </Button>
                            </span>
                        </div>
                    </div>
                </>
            )}
            { showAddListPopup && (
                <>
                    <div className={classes.overlay} onClick={onCancel}/>
                    <div className={classes.position}>
                        <div className={classes.title} style={{margin: '1px 10px 1px 10px'}}>Name of watch list:</div>
                        <div className={classes.textBox}>
                            <input style={{height: '35px', width: '300px', margin: '0px 5px'}}
                               type="text"
                               placeholder="Name"
                               onChange={handleNameInput}
                               onKeyPress={handleKeyPress}
                               value={nameInput}
                            />
                        </div>
                        <div className={classes.title} style={{margin: '1px 10px 1px 10px'}}>Name of watch list:</div>
                        <div className={classes.newListBox}>
                            <textarea style={{height: '200px', width: '550px', textAligh:'top'}}
                              type="text"
                              placeholder="List of stock symbols"
                              onChange={handleNewChange}
                              value={newSymbolsInput.toUpperCase()}
                            />
                        </div>
                        <div className={classes.error}>
                            {createErrorMsg}
                        </div>
                        <div>
                            <span>
                            <Button
                                onClick={handleNewListSubmit}
                                color="info"
                                className={classes.addNameButton}
                            >
                                Add
                            </Button>
                            </span>
                            <span>
                            <Button
                                onClick={onCancel}
                                color="info"
                                className={classes.addNameButton}
                            >
                                Cancel
                            </Button>
                            </span>
                        </div>
                    </div>
                </>
            )}
            { showExplanation && (
                <>
                    <div className={classes.overlay} onClick={onClose}/>
                    <div className={classes.explanation} onClick={onClose}>
                        <div style={{textAlign:'center'}}><span><InfoIcon fontSize="default" /></span><span style={{verticalAlign:'top', fontSize:'18px'}}>Help</span></div>
                        {explanationMsg}
                    </div>
                </>
            )}
        </>
    );
}
