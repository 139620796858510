import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import ReactHtmlParser from "html-react-parser";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Menu from '@material-ui/core/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import CircularProgress from "@material-ui/core/CircularProgress";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import {saveSymbols, getSymbols, getStockIDA} from "../../utils/strategy.utils";
import Button from "../../components/CustomButtons/Button";

const styles = {
    noWarp: {
        whiteSpace: 'nowrap',
    },
    inputBox: {
        fontSize: '16px',
        fontWeight: 200,
        padding: '10px 10px',
        maringTop: '10px',
    },
    submitBox: {
        height: '35px',
        marginLeft: '20px',
        marginTop: '-200px',
        textTransform: 'capitalize',
        fontSize: '12px',
    },
    chartBox: {
        fontFamily: 'Arial, Helvetica',
        height: 'auto',
        margin: '25px 10px',
        fontWeight: 500,
        border: '1px solid #000000DE',
        // maxWidth: '900px',
    },
    tabletitle: {
        fontSize: '16px',
        fontWeight: 550,
        padding: '10px 15px',
        color: '#000000DE',
        backgroundColor: '#e5e0e0',
    },
    symbolslist: {
        fontFamily: 'Arial, Helvetica',
        height: '100px',
        fontSize: '15px',
        fontWeight: 200,
        padding: '25px 30px',
        margin: '25px 50px',
        marginTop: 70,
        color: '#000000DE',
    },
    error: {
        fontSize: '16px',
        fontWeight: 800,
        padding: '3px 0px',
        color: 'red',
    },
    waitingMsg: {
        fontWeight: 400,
        fontSize: 20,
        textAlign: 'center',
        marginTop: 70,
        marginLeft: -80,
    },
    circularProgressTop: {
        marginTop: 20,
    },
    circularProgressDiv: {
        border: '1px solid 1px solid #d3d3d3',
        display: 'flex',
        justifyContent: 'center',
        height: 100,
    },
    idaBox: {
        position: 'relative',
        width: 'auto',
        backgroundColor: 'white',
        padding: '0px',
        borderRadius: '5px',
        margin: '0px 0px 5px 10px',
        fontWeight: '400',
        fontSize: '16px',
        fontFamily: 'Arial, Helvetica',
    },
    title: {
        fontFamily: 'Roboto, Arial, Helvetica',
        fontSize: '16px',
        fontWeight: 200,
        padding: '10px 0px 0px 0px',
        color: '#000000DE',
    },
    functionBox: {
        width: '210px',
        display: 'inline-block',
        height: 'auto',
        padding: '5px 0px 0px 0px',
    },
    menuList: {
        marginLeft: '-15px',
        width: '210px',
        border: '1px solid #000000DE',
        marginTop: '-8px',
        padding: '5px 15px',
        borderRadius: '5px',
    },
    menuItem: {
        color: 'black',
        fontSize: '16px',
        '& .Mui-selected': {
            display: 'block',
            backgroundColor: '#EBEBEB',
        },
        fontFamily: 'Arial, Helvetica',
        color: '#000000DE',
    },
}

const useStyles = makeStyles(styles);

export default function IDAPatternDetectionView() {
    const [symbolsInput, setSymbolsInput] = React.useState("");
    const [idaColumns, setIdaColumns] = React.useState([]);
    const [idaTable, setIdaTable] = React.useState([]);
    const [tableWidth, setTableWidth] = React.useState('1080px');
    const [tableTitle, setTableTitle] = React.useState('');
    const [updateTime, setUpdateTime] = React.useState('');
    const [progress, setProgress] = React.useState(false);
    const [menuIndex, setMenuIndex] = React.useState(1);
    const [showDetection, setShowDetection] = React.useState(false);
    const [showUserList, setShowUserList] = React.useState(false);
    const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
    const open1 = Boolean(anchorEl1);

    const menu_options = [
        'Select Detection Universe',
        'IDA Stock Universe',
        'IDA ETF Universe',
        // 'User Defined Watch List',
    ]

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        index: number,
    ) => {
        setMenuIndex(index);
        setAnchorEl1(null);
        switch (index) {
            case 1:
                setProgress(true);
                setShowDetection(false);
                getDataTable(16, "", 1);
                setShowUserList(false);
                break;
            case 2:
                setProgress(true);
                setShowDetection(false);
                getDataTable(16, "", 2);
                setShowUserList(false);
                break;
            default:
                break;
        }
    };

    const handleClickMenuItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const handleChange = (e) => {
        e.preventDefault();
        setSymbolsInput(e.target.value.toUpperCase());
    };

    const handleSubmit = () => {
        setProgress(true);
        saveSymbols(symbolsInput);
        setTimeout(function () {
            getDataTable(16, "", 3);
        }, 2000);
    }

    function updateHeader(header) : string {
        let newHeader = JSON.stringify(header).replace(/[{\"}]/g,' ').replace(':',' ');
        if (newHeader.indexOf('TA Metrics Buy/Sell Signals') > 0) {
            if (newHeader.indexOf(' 3.0') > 0)
                return newHeader.replace('TA Metrics Buy/Sell Signals 3.0', '<u>Confirmed Buy Signal (Both Trend & Momentum turn positive, v=3)</u>');
            else if (newHeader.indexOf(' 2.0') > 0)
                return newHeader.replace('TA Metrics Buy/Sell Signals 2.0', 'Early Buy Signal (Trend turns positive, v=2)');
            else if (newHeader.indexOf(' 1.0') > 0)
                return newHeader.replace('TA Metrics Buy/Sell Signals 1.0', 'Early Buy Signal (Momentum turns positive, v=1)');
            else if (newHeader.indexOf(' -3.0') > 0)
                return newHeader.replace('TA Metrics Buy/Sell Signals -3.0 ', '<u>Confirmed Sell Signal (Both Trend & Momentum turn negative, v=-3)</u>');
            else if (newHeader.indexOf(' -2.0') > 0)
                return newHeader.replace('TA Metrics Buy/Sell Signals -2.0', 'Early Sell Signal (Trend turns negative, v=-2)');
            else if (newHeader.indexOf(' -1.0') > 0)
                return newHeader.replace('TA Metrics Buy/Sell Signals -1.0', 'Early Sell Signal (Momentum turns negative, v=-1)');
        } else if (newHeader.indexOf('EMA Trend Signals') > 0) {
            if (newHeader.indexOf(' 2.0') > 0)
                return newHeader.replace('EMA Trend Signals 2.0', '<u>Confirmed Uptrend Signal (Price > EMA20 > EMA50, v=2)</u>');
            else if (newHeader.indexOf(' 1.0') > 0)
                return newHeader.replace('EMA Trend Signals 1.0', 'Early Uptrend Signal (Price > EMA20, v=1)');
            else if (newHeader.indexOf(' -2.0') > 0)
                return newHeader.replace('EMA Trend Signals -2.0', '<u>Confirmed Downtrend Signal (Price < EMA20 < EMA50, v=-2)</u>');
            else if (newHeader.indexOf(' -1.0') > 0)
                return newHeader.replace('EMA Trend Signals -1.0', 'Early Downtrend Signal (Price < EMA20, v=-1)');
        } else if (newHeader.indexOf('PSAR Trend Signals') > 0) {
            if (newHeader.indexOf(' 1.0') > 0)
                return newHeader.replace('PSAR Trend Signals 1.0', 'Uptrend Signal (Price > PSAR, v=1)');
            else if (newHeader.indexOf(' -1.0') > 0)
                return newHeader.replace('PSAR Trend Signals -1.0', 'Downtrend Signal (Price < PSAR, v=-1)');
        } else if (newHeader.indexOf('MACD Momentum Signals') > 0) {
            if (newHeader.indexOf(' 2.0') > 0)
                return newHeader.replace('MACD Momentum Signals 2.0', '<u>Confirmed Increasing Momentum Signal (MACD > Signal & MACD > 0, v=2)</u>');
            else if (newHeader.indexOf(' 1.0') > 0)
                return newHeader.replace('MACD Momentum Signals 1.0', 'Early Increasing Momentum Signal (MACD > Signal & MACD < 0, v=1)');
            else if (newHeader.indexOf(' -2.0') > 0)
                return newHeader.replace('MACD Momentum Signals -2.0', '<u>Confirmed Decreasing Momentum (MACD < Signal & MACD < 0, v=-2)</u>');
            else if (newHeader.indexOf(' -1.0') > 0)
                return newHeader.replace('MACD Momentum Signals -1.0', 'Early Decreasing Momentum (MACD < Signal & MACD > 0, v=-1)');
        } else if (newHeader.indexOf('Bolling Band Overbought/Oversold Signals') > 0) {
            if (newHeader.indexOf(' 1.0') > 0)
                return newHeader.replace('Bolling Band Overbought/Oversold Signals 1.0', 'Oversold Signal (Price < Lower BB, v=1)');
            else if (newHeader.indexOf(' -1.0') > 0)
                return newHeader.replace('Bolling Band Overbought/Oversold Signals -1.0', 'Overbought Signal (Price > Upper BB, v=-1)');
        } else if (newHeader.indexOf('RSI Overbought/Oversold Signals') > 0) {
            if (newHeader.indexOf(' 2.0') > 0)
                return newHeader.replace('RSI Overbought/Oversold Signals 2.0', 'Very Oversold Signal (RSI < 20, v=2)');
            else if (newHeader.indexOf(' 1.0') > 0)
                return newHeader.replace('RSI Overbought/Oversold Signals 1.0', 'Oversold Signal (RSI < 30, v=1)');
            else if (newHeader.indexOf(' -2.0') > 0)
                return newHeader.replace('RSI Overbought/Oversold Signals -2.0', 'Very Overbought Signal (RSI > 80, v=-2)');
            else if (newHeader.indexOf(' -1.0') > 0)
                return newHeader.replace('RSI Overbought/Oversold Signals -1.0', 'Overbought Signal (RSI > 70, v=-1)');
        } else if (newHeader.indexOf('Stock Breakout Signals') > 0) {
            if (newHeader.indexOf(' 1.0 ') > 0)
                return newHeader.replace('Stock Breakout Signals 1.0 ', 'Stocks Break-up - Buy Signal (v=1)');
            else if (newHeader.indexOf(' -1.0 ') > 0)
                return newHeader.replace('Stock Breakout Signals -1.0 ', 'Stocks Break-down - Sell Signal (v=-1)');
        } else if (newHeader.indexOf('Current State (R1) Change Event') > 0) {
            return newHeader;
        } else if (newHeader.indexOf('Current State (H1) Change Event') > 0) {
            return newHeader;
        } else if (newHeader.indexOf('Current 5 Day Prediction Change Event') > 0) {
            return newHeader;
        } else if (newHeader.indexOf('Current 5 Days Prediction (S1) Change Event') > 0) {
            return newHeader;
        } else if (newHeader.indexOf('Current 20 Days Prediction (M1) Change Event') > 0) {
            return newHeader;
        }
        return "";
    }

    const getDataTable = (func, symbols, dim) => {
        getStockIDA(symbols, func, 1, 1, dim)
            .then((response) => {
                if (response.data.code === 200) {
                    const data = response.data.data;
                    setProgress(false);
                    if (data.idaTable !== null) {
                        setIdaTable(data.idaTable);
                        setShowDetection(true);
                    }
                    if (data.updateTime !== null) {
                        setUpdateTime(data.updateTime)
                    }
                } else  {
                    const err = response?.data?.errMsg? response.data.errMsg : null;
                    setIdaColumns([]);
                    setIdaTable([]);
                    setProgress(false);
                }
            })
            .catch((error) => {
                setIdaColumns([]);
                setProgress(false);
                setIdaTable([]);
                console.debug(error);
            });
    }

    const getUserSymbols = () => {
        getSymbols()
            .then((response) => {
                if (response.data.code === 200) {
                    const data = response.data.data;
                    if (data)
                        setSymbolsInput(data);
                    else
                        setSymbolsInput('');
                }
            })
            .catch((error) => {
                console.debug(error);
            });
    }

    function showList(item) {
        sum += item;
    }

    React.useEffect(() => {
        setProgress(true);
        getDataTable(16, "", 1);
    }, []);

    const classes = useStyles();

    return (
        <>
            <div>
                <div className={classes.idaBox}>
                <div className={classes.title} style={{marginTop: '15px'}}>Select one of the Universes:</div>
                    <span className={classes.functionBox}>
                            <Box
                                ml={2}
                                sx={{ width: '50%',
                                    maxWidth: 200,
                                }}
                            >
                                <List
                                    component="nav"
                                    aria-label="Functions"
                                >
                                    <ListItem
                                        className={classes.menuList}
                                        button
                                        id="lock-button"
                                        aria-haspopup="listbox"
                                        onClick={handleClickMenuItem}
                                    >
                                        <ListItemText
                                            primary={menu_options[menuIndex]}
                                            secondary="comparison"
                                        />
                                    </ListItem>
                                </List>
                                <Menu
                                    className={classes.menuItem}
                                    id="function-menu"
                                    anchorEl={anchorEl1}
                                    open={open1}
                                    onClose={handleClose1}
                                    MenuListProps={{
                                        'aria-labelledby': 'lock-button',
                                        role: 'listbox',
                                    }}
                                >
                                    {menu_options.map((option, index) => (
                                        <MenuItem
                                            className={classes.menuItem}
                                            key={index}
                                            disabled={index === 0}
                                            selected={index === menuIndex}
                                            onClick={(event) => handleMenuItemClick(event, index)}
                                        >
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        </span>
                </div>
                <hr style={{margin: '15px 0px 5px 0px'}}/>
                { progress && (
                    <div className={classes.circularProgressDiv}>
                        <CircularProgress className={classes.circularProgressTop} />
                        <div className={classes.waitingMsg}>Please wait...</div>
                    </div>
                )}
                { showDetection && (
                    <>
                        <div className={classes.chartBox} style={{maxWidth : tableWidth}}>
                            {idaTable.map((detection) => (
                                <>
                                    <div className={classes.tabletitle} style={{maxWidth : tableWidth}}>{detection.header} <small style={{fontSize: '14px'}}>(Based on End Of Day Data of {updateTime})</small></div>
                                    {detection.detectionList.map((d, id) => {
                                        const content = updateHeader(d);
                                        if (content != '') {
                                            return (
                                                <>
                                                    <div key={id} calssName={classes.symbolslist} style={{
                                                        maxWidth: tableWidth,
                                                        minHeight: '30px',
                                                        margin: '10px, 25px',
                                                        padding: '15px'
                                                    }}>{ReactHtmlParser(content)}</div>
                                                    <hr style={{margin: '0px'}}/>
                                                </>
                                            )
                                        }
                                    })}
                                </>
                            ))}
                        </div>
                        {/*<hr style={{margin: '5px'}}/>*/}
                    </>
                )}
            </div>
        </>
    );
}
